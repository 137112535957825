import * as React from 'react';
import { Helmet } from 'react-helmet';

import { IMetaTagField } from '../../utils/types';

export interface IHeadMetaProps {
  title?: string;
  meta?: IMetaTagField[];
  lang?: string;
}

export const HeadMeta: React.FunctionComponent<IHeadMetaProps> = (props) => {
  const metaTitles = props.meta?.map((m) => m.name) || [];
  const title =
    metaTitles.filter(Boolean).length > 0
      ? metaTitles.filter(Boolean)[0]
      : props.title;
  const metaContent = props.meta?.map((m) => m.content) || [];
  return (
    <Helmet htmlAttributes={{ lang: props.lang || 'de-DE' }}>
      {title && <title>{title}</title>}
      {React.Children.toArray(
        metaContent
          .filter(Boolean)
          .map((content) =>
            content === 'noindex, nofollow' ? (
              <meta name="robots" content={content} />
            ) : (
              <meta name="description" content={content} />
            )
          )
      )}
    </Helmet>
  );
};
